// storybook-check-ignore
import React, { PropsWithChildren, useRef, useState } from 'react';

import { css } from '@emotion/react';
import { useAuth } from '@opendoor/auth-fe';
import { Box as NovoBox, Text } from '@opendoor/bricks-next';
import { Button, Link } from '@opendoor/bricks/core';
import Box from '@opendoor/bricks/core/Box';
import { novo } from '@opendoor/bricks/theme';
import { useRouter } from 'next/router';

import usePrevious from 'components/landing-pages-v2/utils/usePrevious';

import { useObservability } from '../../../../helpers/observability';
import AccountMenu from '../../../account/AccountMenu';
import { COSMOS_URL } from '../../../globals';
import { DEFAULT_NAV_MENU_BUTTON_ID, DEFAULT_NAV_MENU_ID } from '../../../shared/MenuPanel';
import MenuPanel from '../menu/MenuPanel';
import { defaultEaseCss } from '../motion/MotionVariants';
import { OpendoorLogo, OpendoorLogoBadge } from '../OpendoorLogo';
import { GlobalNavSVG } from './GlobalNavSVG';

// move to def file
export interface IMenuLink {
  name: string;
  href: string;
}

interface HeaderProps {
  slug: string;
  menuPanelNavLinks: IMenuLink[];
  mainNavLinks: IMenuLink[];
  ctaTitle?: string;
  ctaLink?: IMenuLink;
}

const redirectToLogin = () => {
  document.location.href = `${COSMOS_URL}/login?redirectTo=${encodeURIComponent(
    window.location.pathname,
  )}`;
};

const headerHeight = '72px';

const Header = ({ slug, menuPanelNavLinks, mainNavLinks, ctaTitle, ctaLink }: HeaderProps) => {
  const { pathname } = useRouter();

  const navMenuButtonId = DEFAULT_NAV_MENU_BUTTON_ID;
  const navMenuId = DEFAULT_NAV_MENU_ID;

  const { trackEvent } = useObservability();
  const [isExpanded, setExpanded] = useState(false);
  const { user: human, authentication } = useAuth();

  const isLoggedIn = authentication.state === 'authenticated';

  const fullName = human?.fullName;
  const firstName = fullName ? fullName.split(' ')[0] : '';
  const initials = fullName ? fullName.split(' ').map((n) => n[0]) : [];
  const [isVisible, setIsVisible] = useState(false);
  const prevVisible = usePrevious(isVisible);
  const toggleMenuOpen = () => setIsVisible(!isVisible);

  const handleMenuClick = () => {
    // TODO: remove categoryOverride
    // observability is initialized with 'cosmos' as the category for all events
    trackEvent('cta-click', 'menu', undefined, { categoryOverride: 'homepage' });
    toggleMenuOpen();
  };

  const handleAccountClick = () => {
    // TODO: remove categoryOverride
    // observability is initialized with 'cosmos' as the category for all events
    trackEvent('cta-click', 'account-icon', undefined, { categoryOverride: 'homepage' });
    authentication.state === 'authenticated' ? setExpanded(!isExpanded) : redirectToLogin();
  };

  // Hang on to burger button ref for focus management
  const ctaRef = useRef<HTMLButtonElement>(null);

  // When menu close animation is complete
  // and it was previously open.
  // This prevents a focus from the initial animation state
  // on page load.
  const exitComplete = () => {
    if (prevVisible === true) {
      ctaRef.current?.focus();
    }
  };

  //Hide Sign button on the sign in page
  const showLogin = !(pathname.includes('/login') || pathname.includes('/account'));

  const LoginSection = () => {
    if (!showLogin) return null;

    return (
      <Link
        href={'#'}
        fontWeight="medium"
        analyticsName="cosmos-sign-in"
        aria-label=""
        color="warmgrey950"
        _hover={{
          color: 'brandBlue600',
        }}
        _focus={{
          color: 'brandBlue600',
          outline: 0,
        }}
      >
        Sign in
      </Link>
    );
  };

  // when there are more than two nav links we collapse into menu panel for tablet
  const tabletDisplay = mainNavLinks.length > 2 ? null : 'flex';

  return (
    <>
      <MenuPanel
        slug={slug}
        menuPanelNavLinks={menuPanelNavLinks}
        mainNavLinks={mainNavLinks}
        ctaTitle={ctaTitle}
        ctaLink={ctaLink}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        exitComplete={exitComplete}
      />

      <Box
        background="white0"
        h={headerHeight}
        position="sticky"
        top="0"
        zIndex="2000"
        pl={[20, null, 0]}
        pr={[20, null, 0]}
      >
        <Box
          display="grid"
          gridTemplateColumns="auto 1fr auto"
          h={headerHeight}
          marginX="auto"
          px={[null, null, 9, 11, 11]}
          maxW="1440px"
        >
          <SkipLink />
          <Box
            gridColumn="1 / 2"
            gridRow="1 / 2"
            pr="16px"
            display="flex"
            alignItems="center"
            zIndex="5"
          >
            <Link
              color="warmgrey950"
              href="/"
              analyticsName="cosmos-logo-link"
              aria-label="Go to Home page"
              display={['none', null, 'flex']}
            >
              <OpendoorLogo width="96px" />
            </Link>
            <Link
              color="warmgrey950"
              href="/"
              analyticsName="cosmos-logo-link"
              aria-label="Go to Home page"
              display={['flex', null, 'none']}
            >
              <OpendoorLogoBadge />
            </Link>
          </Box>
          {/* 1 / -1 keeps things centered despite size of left and right columns */}
          <Box gridColumn="1 / -1" gridRow="1 / 2">
            <Box
              as="ul"
              m="0"
              p="0"
              display={['none', null, tabletDisplay, 'flex']}
              justifyContent="center"
              analyticsName="cosmos-landing-page-menu-list"
              css={css`
                list-style: none;
              `}
            >
              {mainNavLinks.map((nav) => {
                return (
                  <Box as="li" key={nav.name}>
                    <NavLink active={slug === nav.href} href={nav.href} name={nav.name}>
                      {nav.name}
                    </NavLink>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            display="flex"
            gridRow="1 / 2"
            gridColumn="-1 / -2"
            alignItems="center"
            gap="24px"
            pl="16px"
          >
            <Box
              onClick={handleAccountClick}
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {isLoggedIn ? (
                <Box
                  display="inline-flex"
                  cursor="pointer"
                  fontWeight="medium"
                  gap="4px"
                  _hover={{
                    color: 'brandBlue600',
                  }}
                >
                  <Box>
                    <Text tag="span" $smallerThanMD={{ display: 'none' }} typography="$labelMedium">
                      Welcome {firstName}
                    </Text>
                    <Text
                      tag="span"
                      typography="$labelSmall"
                      $largerThanSM={{ display: 'none' }}
                      borderRadius="$60x"
                      backgroundColor="$backgroundInversePrimary"
                      color="$contentInversePrimary"
                      p={10}
                    >
                      {initials}
                    </Text>
                  </Box>
                  <NovoBox width={24} height={24} position="relative" $smallerThanMD={{ top: 3 }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      style={{
                        position: 'absolute',
                        transition: `0.15s ${defaultEaseCss} transform`,
                        transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)',
                        transformOrigin: 'center',
                        left: '50%',
                        top: '50%',
                        margin: '-4px 0 0 -7px',
                      }}
                    >
                      <path
                        d="M13 0.999999L7 7L1 1"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </NovoBox>
                  {/*
                    top is an offset of the AccountMenu component
                    right is and offset of the Account Menu component and aligning the arrows
                   */}
                  <Box
                    position="absolute"
                    top="-35px"
                    css={css`
                      right: -35px;
                      @media (max-width: 1201px) {
                        right: -30px;
                      }
                      @media (max-width: 767px) {
                        right: -20px;
                        left: inherit;
                      }
                    `}
                  >
                    <AccountMenu isExpanded={isExpanded} />
                  </Box>
                </Box>
              ) : (
                <LoginSection />
              )}
            </Box>
            <Box>
              <Button
                analyticsName="cosmos-landing-page-header-menu-button"
                aria-label="Navigation menu"
                aria-controls={navMenuId}
                aria-expanded={isVisible ? 'true' : 'false'}
                aria-haspopup="true"
                id={navMenuButtonId}
                ref={ctaRef}
                border={0}
                color="warmgrey950"
                height="24px"
                minHeight="24px"
                width="24px"
                p={0}
                outline="0"
                onClick={handleMenuClick}
                _hover={{
                  borderRadius: 'roundedSquare',
                  color: 'brandBlue600',
                }}
                _active={{
                  borderRadius: 'roundedSquare',
                  scale: 1,
                }}
                _focus={{
                  borderRadius: 'roundedSquare',
                  color: 'warmgrey950',
                  outline: 0,
                }}
                _focusVisible={{
                  borderRadius: 'roundedSquare',
                  color: 'brandBlue600',
                  outline: 0,
                }}
                style={{
                  background: 'none',
                  borderRadius: 'roundedSquare',
                }}
              >
                <GlobalNavSVG isOpen={isVisible} />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;

export type NavLinks = Array<{
  name: string;
  href: string;
  isActive?: boolean;
}>;

const NavLink: React.FC<
  PropsWithChildren<{ href: string; elementName?: string; active?: boolean; name: string }>
> = (props) => (
  <Link
    alignItems="center"
    color="warmgrey950"
    display="inline-flex"
    lineHeight="100"
    fontWeight="medium"
    py={3}
    px={5}
    h={headerHeight}
    css={css`
      &:visited {
        color: ${novo.colors.warmgrey950};
      }
      &:hover,
      &:focus {
        color: ${novo.colors.brandBlue600};
        outline: 0;
      }
      &:active {
        color: ${novo.colors.brandBlue600};
      }
    `}
    {...props}
    analyticsName={`cosmos-landing-page-v2-nav-link-${props.name}`}
    aria-label="View link"
    href={props.href}
    aria-current={props.active}
  >
    {props.children}
  </Link>
);

const SkipLink: React.FC = (_props) => {
  return (
    <Button
      border={0}
      color="brand50"
      justifyContent="center"
      left={0}
      outline={0}
      position="fixed"
      top="0"
      transform="translateY(-100%)"
      width="100%"
      zIndex={1}
      display="flex"
      backgroundColor="neutrals0"
      aria-label="Skip navigation menu and view main content"
      _focus={{
        transform: 'translateY(0)',
      }}
      analyticsName="cosmos-landing-page-v2-skip-to-main-content"
      onClick={() => {
        // there should only be 1 main on any page and it should wrap the main content
        const mainElem = document.querySelector('main');
        mainElem && mainElem.focus();
      }}
    >
      Skip to main content
    </Button>
  );
};
